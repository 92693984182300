import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from './site/Componentes/footer';
import Menu from './site/Componentes/menu';



function App() {
  return (
    
    <div>
      <Menu />      
      <div>
        <Outlet />
      </div>
      <Footer/>
    </div>
    
  );
}

export default App;
