import React from 'react';

function Footer(){
    return <section id='footer'>
        <div className='container'>

            <div className="bottom section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="copyright">
                                <p>© <span>2022</span> <a href="https://www.softcominfo.com.br" className="transition">Softcom Informática</a> Todos Direitos Reservados.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>            
    </section>;
  }

export default Footer;  



