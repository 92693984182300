import React from 'react';

function Features(){
    return <section id='features'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 feature-box'>                    
                    <i className="icon fa-solid fa-file-invoice fa-5x"></i>                 
                    <h3>Documentos Fiscais</h3>
                    <p>Principais documentos fiscais exigidos, como: NFe, MDFe, CTe integrados com o faturamento.</p>
                </div>

                <div className='col-lg-4 feature-box'>
                    <i className="icon fa-solid fa-id-card fa-5x"></i>
                    <h3>Um ERP que você vai gostar de usar</h3>
                    <p>Tenha uma visão completa do seu negócio. Garanta estoque mínimo, eficiência nos processos logísticos e lucratividade, tudo controlado pelo ERP.</p>
                
                </div>

                <div className='col-lg-4 feature-box'>
                    <i className="icon fa-solid fa-comments-dollar fa-5x"></i>
                    <h3>É personalizável</h3>
                    <p>Gerenciando o seu negócio com o ERP Svendas, você vai escalar seu crescimento racionalizando, diminuindo os custos operacionais e aumentando suas margens. </p>
                
                </div>
            </div>
        </div>
    </section>;
  }

export default Features;  