import { useState } from 'react';
import emailjs from '@emailjs/browser'
import './Contact.css';

function Contact() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')


  function Enviar(e){
    e.preventDefault();
    if(name === '' || email === '' || message === ''){
        alert('Preencha todos os campos');
        return;
    }        

    const bodyMessage = {
        to_name: 'Softcom',
        from_name: name,
        to_Name: email ,
        message: message
    }

    emailjs.send("service_kn1kbsg","template_tzs9ouy", bodyMessage, 'jqzNGvayLKTtpW0vZ')
      .then((response) => {
        console.log('Email Enviado!!! ', response.status, response.text)
        setName('')
        setEmail('')
        setMessage('')
      }, (err) => {
        console.log(err)
      })       

  }



  return (
    <div className="container_contact">
      <h1 className="title">Contato</h1>

      <form className="form" onSubmit={Enviar}>
        <input 
          className="input"
          type="text"
          placeholder="Digite seu nome"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        
        <input 
          className="input"
          type="text"
          placeholder="Digite seu email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />

        <textarea 
          className="textarea"
          placeholder="Digite sua mensagem..."
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />

        <input className="button" type="submit" value="Enviar" />
      </form>

    </div>
  );
}

export default Contact;