import React from 'react';
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from './App.jsx';
import Banner from './site/Componentes/banner';
import Features from './site/Componentes/features';
import Contact from './site/Componentes/Contact.jsx';
import Sobre from './site/Componentes/Sobre.jsx';


const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <Banner />,
      },
      {
        path: "/features",
        element: <Features />,
      },      
      {
        path: "/contato",
        element: <Contact />,
      },
      {
        path: "/sobre",
        element: <Sobre />,
      }     
    ],
  },
]);



  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>      
        <RouterProvider router={router} />      
    </React.StrictMode>
);

