import React from 'react';
import { FaStarOfLife } from 'react-icons/fa';
import './banner.css';


function Banner(){
    return <section id='banner'>
            <div className='softcom_titulo'>                               
                 <h3 className='display-4'>Desenvolvimento de Softwares</h3>                
                 <FaStarOfLife className='FaStarOfLife px-md-1'/>                    
            </div>            

        <div className='container'>            

            <div className='row pt-3'>
                <div className='col-lg-7'>
                    <h1>Software de Gestão Comercial simples e fácil de usar</h1>
                    <h4>Um ERP com funcionalidades escaláveis para qualquer porte</h4>
                    <h4>Conte com um software completo, totalmente integrado para sua empresa</h4>

                    {/*<a href="/app/novaconta" className='btn btn-dark btn-lg btn-banner'>Criar uma Conta</a>
                    <a href='/app' className="btn btn-outline-light btn-lg btn-banner">Fazer Login</a>*/}

                </div>
                
                <div className='col-lg-5'>
                    <img src="/Images/ImagemHome.jpg" alt="Softcom" height={500} />
                </div>
            </div>
        </div>
    </section>;
  }

export default Banner;  