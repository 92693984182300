import React from 'react';

function Sobre(){
    return <section id="sobre">
        <div>
        <img src="/Images/ImgSobre.jpg" alt="Softcom" height={250} />

        </div>
        <div>
            <p>
               A Softcom é uma empresa fundada em Julho de 2000 e atua na área de Desenvolvimento de Software Comercial, desenvolvimento Mobile e assessoria na área de TI.
            </p>
        </div>            
    </section>    
  }

export default Sobre;  