import React from 'react';
import { Link } from "react-router-dom";

function Menu(){
    return <nav className="navbar fixed-top navbar-expand-md navbar-dark">

    <div className='container'>

      <a className="navbar-brand" href="/#">
        <img src="Images/Softcom.png" alt="" height="48"/>
      </a>
      
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <Link className="nav-link" to={'/'}>Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={`/features`}>Features</Link>
          </li>          
          <li className="nav-item">
            <Link className="nav-link" to={`/sobre`}>Sobre</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={`/contato`}>Contato</Link>
          </li>          
          <li className="nav-item">
            {/* <Link className="nav-link" to="www.softcominfo.com.br">Download</Link> */}
            <a className="nav-link" href="https://drive.google.com/drive/folders/16qYw4dQatoXLUextpgGTcY9xGDql8LWl">Download</a>
          </li>          
        </ul>
      </div>

    </div>        
  </nav>;
  }

export default Menu;  